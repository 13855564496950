import { clone } from "@amcharts/amcharts4/.internal/core/utils/Object";

/**
 * API URLS
 */
export const baseUrl = 'https://bl.appening.xyz/'
export const urls = {
  login: "auth/api/login",
  regestrationOtp: "auth/api/registation-otp",
  getOtp: "auth/api/send-login-otp",
  verifyOtp: "auth/api/verify-login-otp",
  verifyOtpRegistration: "auth/api/verify-registation-otp",
  verifiyOtpProfile: "api/user/verify-profile-otp",
  registartion: "auth/api/registation",
  emailLinkVerification: "auth/api/user-email-link-verification",
  emailLinkVerificationadmin: "auth/api/validate-admin-email-link",

  register: "auth/api/register",
  updateProfile: "api/user/profile",
  OtpProfile: "auth/api/verify-profile-otp",
  sendPasswordLink: "auth/api/forget-password/send-email",
  resetPassword: "auth/api/forget/create-password",
  adminLogin: "auth/api/admin/login",
  createStaffPassword: "auth/api/staff/create-password",
  resetStaffPassword: "auth/api/staff/reset-password",
  sendPasswordLinkStaff: "auth/api/staff/create-password/send-email",
  sendResetPasswordLinkStaff: "auth/api/staff/reset-password/send-email",
  sendProfileOtp: "api/user/send-profile-phone-otp",
  application: "api/application",
  getApplicationList: "api/applications",
  getApplicationById: "api/application-by-id",
  associateList: "api/associate-permits",
  resetAssociate: "api/reset-associate-permit",
  addACHAccount: "api/ach-account",
  verifyACHAccount: "api/ach-account/verify",
  verifyCreditCard: "api/ach-account/card/verify",
  deleteACHAccount: "api/ach-account/delete",
  getACHAccount: "api/ach-account",
  achAccountList: "api/ach-account",
  achAccountListIndex: "api/ach-account-index",
  getApplicationFee: "api/application/fee",
  applicationPayment: "api/application-payment",
  searchAddress: "api/city/address/",
  getCityList: "api/cities",
  SubTypeList: "api/city-sub-type-list",
  getAddressByPermitNumber: "api/get-address-by-permit-number",

  downloadPermit: "api/admin/download-certificate/",
  newDownloadPermit: "api/admin/download-business-license",
  getUserDataLineChart: "api/admin/dashboard/active-user-chart",
  deleteOwnerOfficer: "api/delete/application/owner",
  editOwnerOfficer: "api/ownership/update",
  applicationResubmit: "api/application/resubmit",
  applicationAdditionalInfo: "api/application-form",
  applicationAdditionalInfoDocUpload: "api/application-form/document",
  getApplicationAdditionalInfo: "api/application-form",
  additionalInfoDocDelete: "api/application-form/delete",
  UsertypeList: "api/city-type-list",
  getCSRFToken: "api/csrf-token",
  verifyCSRFToken: `api/verify-csrf-token`,
  associatePermit: `api/associate-permit`,
  documentUploadUser: "api/application/document",
  einDocumentList: "api/upload-permits",
  einDocumentUpload: "api/application/upload-permit",
  getTimeLineUser: "api/application-timeline",
  zipCodeList: "api/zip-code-list",
  storeList: "api/search-ein-business",
  cloneApplication: "api/application/clone",
  getAdminApplication: "api/admin/applications",
  getCityAdminList: "api/admin/city-admin",
  addCityAdmin: "api/admin/city-admin",
  saveAddress: "api/admin/upload/address",
  addStaffByCityAdmin: "api/admin/staff",
  getCityAdminStaffList: "api/admin/staff",
  updateCityAdmin: "api/admin/city-admin",
  adminApplicationById: "api/admin/application/",
  postSubmissionReview: "api/admin/clerk-submission-review",
  voidSubmissionReview: "api/admin/clerk-submission-review/void",
  applicationUpdateByAdmin: "api/admin/application/update",
  postDecision: "api/admin/decision",
  finalDecision: "api/admin/final/decision",
  approvalPlice: "api/admin/approval",
  editApplication:"api/admin/update/bl-application",

  emailResendVerificationadmin: "api/admin/resend-email-link",
  managerUploadRefund: "api/admin/upload-refund-policy",
  finalAprovalPolice: "api/admin/final/approval",
  voidDecision: "api/admin/decision/void",
  voidApproval: "api/admin/approval/void",
  submitNotes: "api/admin/notes",
  postEmail: "api/admin/send-mail",
  deleteNote: "api/admin/notes/delete",
  postPayment: "api/admin/add/fee",
  waiveOffFee: "api/admin/waive-off/fee",
  managerProfileUploadSignature: "api/admin/city-admin/profile",
  cityAdminStaffResetPassword: "api/admin/staff/password/create",
  addLicenseFee: "api/admin/add-license-fee",
  updateLicenseFee: "api/admin/update-license-fee",
  deleteLicenseFee: "api/admin/remove-license-fee",
  deleteOwnerOfficerAdmin: "api/admin/ownership/remove",
  editOwnerOfficerAdmin: "api/admin/ownership/update",
  getCityAdminById: "api/admin/staff-details",
  sendApplicationAdditionalInfoAdmin: "api/admin/send-application-form-link",
  getAdminPaymentList: "api/admin/payment-summary",
  getAdminPaymentSummary: "/api/admin/payment-summary",
  getExportDataFile: "/api/admin/export-payment-history/Excel",
  deleteDocument: "api/delete/application/document",
  einDeleteDocument: "api/application/delete-upload-permit",
  ein: "api/other-permits",
  addType: "api/admin/add-type",
  adminAssociateList: "api/admin/associate-permits",
  deletePermits: "api/admin/delete/associate-permit",
  typeList: "api/admin/city-type-list",
  allTypeList: "api/admin/city-all_type-list",
  subTypeList: "api/admin/city-sub-type-list",
  addSubType: "api/admin/add-sub-type",
  zipCodeLists: "api/admin/zip-code-list",
  saveZipCodeCSVFile: "api/admin/upload/zip-code",
  deleteType: "api/admin/delete-type",
  deleteSubType: "api/admin/delete-sub-type",
  documentAdmin: "api/admin/application/document",
  deleteDocumentAdmin: "api/admin/delete/application/document",
  deleteOwner: "api/admin/delete/application/owner",
  adminSearchEin: "api/admin/other-permits",
  adminAssociate: "api/admin/associate-permit",
  getTypeList: "api/admin/city/types",
  getSubTypeList: "api/admin/type",
  getTimeLine: "api/admin/application-timeline",
  dashboardMail: "api/admin/dashboard-mail",
  adminDasboard: "api/admin/dashboard",
  adminHistory: "api/admin/businesses",
  adminexpirePermit: "api/admin/dashboard/expire-permit",
  issuedDeniedPermit: "api/admin/dashboard/issued-denied-permit",
  sendSmsExpirePermit: "api/admin/send-sms",
  admineinDocumentList: "api/admin/upload-permits",
  einAdmimDeleteDocument: "api/admin/application/delete-upload-permit",
  einAdminDocumentUpload: "api/admin/application/upload-permit",
  adminDeleteDocumentEin: "api/admin/application/delete-upload-permit",
  sendOtpProfile: "api/admin/send-profile-phone-otp",
  verifiyOtpProfileadmin: "api/admin/verify-profile-otp",
  verifyCSRFTokenAdmin: `api/admin/verify-csrf-token`,
  submitHelp: "api/need-help",
  renewPermit:'api/renew-permit',
};
