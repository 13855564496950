import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { appToaster } from 'src/app/configs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { AdminService } from 'src/app/core/services/admin/admin.service';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { Location } from '@angular/common';
import * as CryptoJS from "crypto-js";
@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit, OnDestroy {

  public currentUser;
  public isApplicationEditable = false;
  public signatureFileName:string ;
  public isSignatureUploaded = false;
  public selectedFile: any;
  public isSubmit = false;
  public isUploadType:any;
  public login_by: any;

  @ViewChild('signatureUpl', { static: false }) signatureUpl: ElementRef;
  @ViewChild('Refund_upload', { static: false }) Refund_upload: ElementRef;


  private subscription: Subscription = new Subscription();

  constructor(public authenticationService: AuthenticationService,private route: ActivatedRoute, private toasterService: ToastrService, private router: Router, private adminService: AdminService,private location: Location) { }
  decryptedData: any;
  secretKey = "mySecretKey123";
  encryptedData: any;
  isActiveAp = true;
  isActiveBl = true;
  isActiveCB = true;
  isActiveTW = true;
  isActiveDG = true;
  isActiveCE = true;
  isActiveCoop = true;
  ngOnInit() {
    this.interval() ;
    this.subscription.add(this.route.queryParams.subscribe((data: any) => {
      console.log("data is ================>",data)
      if (data && data.encryptedData) {
        this.encryptedData = data.encryptedData;
        sessionStorage.setItem('encryptedData', data.encryptedData);
        sessionStorage.setItem('csrfToken', data.csrfToken);
        const [path, query] = this.location.path().split('?');
        const params = new HttpParams({ fromString: query });
          this.location.replaceState(path, params.delete('encryptedData').toString());

      }
      // if (data && data.csrfToken && data.encryptedData) {
      //   this.subscription.add(this.applicationService.verifyCSRFToken({ token: data.csrfToken }).subscribe((data: any) => {
      //     this.authenticationService.loginDetailsBs.next(data.response);
      //     this.authenticationService.loggedOutBs.next(false);
      //     this.getCityList();
      //     this.getUserDeatil();
      //     this.applicationService.setSaveAndExit(false);
      //     const [path, query] = this.location.path().split('?');
      //     const params = new HttpParams({ fromString: query });
      //     this.location.replaceState(path, params.delete('csrfToken').toString());
      //   }));
      // } else {
       
      // }
    }));
    if (sessionStorage.getItem('encryptedData')) {
      this.loginData();
    }
     

  }
  loginData() {
    this.encryptedData = sessionStorage.getItem('encryptedData');
      const stringWithoutPlus = this.encryptedData.replace(/ /g, "+");
      const decryptedBytes = CryptoJS.AES.decrypt(
        stringWithoutPlus,
        this.secretKey
      );
      let decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
      this.decryptedData = JSON.parse(decryptedData);
      console.log("decryptedData", this.decryptedData);
      debugger;
      if (this.decryptedData) {
        this.checkLoginStatus();
      }
    }
    checkLoginStatus() {
      this.isActiveAp = false;
      this.isActiveBl = false;
      this.isActiveCB = false;
      this.isActiveTW = false;
      this.isActiveDG = false;
      this.isActiveCE =  false;
      this.isActiveCoop = false;
      if (this.decryptedData.login_by == 1 || this.decryptedData.login_by == 3) {
        this.isActiveAp = true;
        this.isActiveBl = true;
        this.isActiveCB = true;
        this.isActiveTW = true;
        this.isActiveDG = true;
        this.isActiveCE = true;
        this.isActiveCoop = true;
        return true;
      }
      if (this.decryptedData.login_by == 2) {
        if (this.decryptedData.status == 1) {
          this.isActiveAp = true;
        }
        if (this.decryptedData.bl_status == 1) {
          this.isActiveBl = true;
        }
        if (this.decryptedData.tw_status == 1) {
          this.isActiveTW = true;
        }
        if (this.decryptedData.cb_status == 1) {
          this.isActiveCB = true;
        }
        if (this.decryptedData.dg_status == 1) {
          this.isActiveDG = true;
        }
        if(this.decryptedData.coop_status == 1){
          this.isActiveCoop = true;
        }
        if (this.decryptedData.ce_status == 1) {
          this.isActiveCE= true;
        }
      }
    }
  intervalID:any;
  getUserDetail():any{
    this.subscription.add(this.authenticationService.getUserInfo().subscribe(user => {
      this.currentUser = user ? user : null;
      console.log("user Data",this.currentUser)

      if( this.currentUser){
        console.log("user Data")
        clearInterval(this.intervalID);
      }
    })
    );
  }
  interval(){
     this.intervalID = setInterval(() => {
      this.getUserDetail()
    }, 2000);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  onClickDog() {
    this.login_by = this.decryptedData ? this.decryptedData.login_by : null;
    if (!this.decryptedData || this.login_by == 1) {
      let url = environment.dogUrl;
      // url = 'http://localhost:9000/'
      window.open(url);
    } else if ((this.login_by == 2 || this.login_by == 3) && this.isActiveDG) {
      let url = environment.dogUrl;
      // url = 'http://localhost:9001/'
      window.location.href = `${url}${"admin/index"}?csrfToken=${
        this.decryptedData.token
      }`;
    }
  }

  onClickLogout() {
    this.authenticationService.logout();
    this.toasterService.success(appToaster.logoutSuccess);
    window.location.href = environment.alarmPermitHost;

  }

  navigateToCityAdmin() {
    this.router.navigate(['admin/city-admin']);
  }
  navigateToAddlicenessType(){
    this.router.navigate(['admin/add-subType']);

  }

  navigateToStaffAdmin() {
    this.router.navigate(['admin/staff']);
  }

  navigateToAdminIndexPage() {
    this.router.navigate(['admin/index']);
  }

  signatureUpload(isUploadType) {
    debugger;
    const formData = new FormData();
    this.isSubmit = true;
    if (this.selectedFile && isUploadType  ==1 ) {
      formData.append('signature', this.selectedFile );
      this.subscription.add(this.adminService.managerUploadSignature(formData).subscribe((data: any) => {
        this.authenticationService.setUser(data);
        this.toasterService.success("Signature uploaded successfully");
        this.subscription.add(this.authenticationService.getUserInfo().subscribe(user => {
          this.currentUser = user ? user : null;
        }));
        this.signatureUpl.nativeElement.click();
        this.clear();
      }));
    } else if(this.selectedFile && isUploadType  ==2){
      formData.append('refund_policy_document', this.selectedFile );
      this.subscription.add(this.adminService.managerUploadRefund(formData).subscribe((data: any) => {
        this.authenticationService.setUser(data);
        
        this.toasterService.success("Refund policy uploaded successfully");
        this.Refund_upload.nativeElement.click();
        this.clear();
        this.subscription.add(this.authenticationService.getUserInfo().subscribe(user => {
          this.currentUser = user ? user : null;
        }));
       
      }));
    }
    else {
      return false;
    }
  }

  clear() {
    this.isSubmit = false;
    this.isSignatureUploaded = false;
    this.selectedFile = null;
    this.signatureFileName = '';
  }
  uploadType(type){
    this.isUploadType = type
    console.log("type",this.isUploadType)
    this.clear();
  }
  selectFile(event) {
    this.selectedFile = event.target.files[0];
    this.signatureFileName = event.target.files[0].name;
    this.isSignatureUploaded = true
  }

  openUploadedSignature() {
    if (this.currentUser.signature) {
      window.open(environment.host + environment.imageBasePath + this.currentUser.signature, '_blank');
    }
  }
  openUploadedRefundPolcy() {
    if (this.currentUser.signature) {
      window.open(environment.host + environment.imageBasePath + this.currentUser.signature, '_blank');
    }
  }

  deleteUploadedSignature() {
    if (this.currentUser.signature && this.currentUser.signature != 'null') {
      const formData = new FormData();
      formData.append('signature', null );
      this.subscription.add(this.adminService.managerUploadSignature(formData).subscribe((data: any) => {
        this.authenticationService.setUser(data);
        this.toasterService.success('Uploaded signature deleted successfully');
        this.subscription.add(this.authenticationService.getUserInfo().subscribe(user => {
          this.currentUser = user ? user : null;
        })
        );
      }));
      this.signatureUpl.nativeElement.click();
    }
  }
  deleteUploadedRefund(){
    
  }
  url:any;
  switchToOtherPortal(val) {
    const encryptedData =  sessionStorage.getItem('encryptedData');
    const csrfToken = sessionStorage.getItem('csrfToken');
    // console.log("token",csrfToken)
    if (val == 1) { 
      // environment.cannabisUrl
      window.location.href = `${environment.cannabisHost}admin/index?csrfToken=${csrfToken}&encryptedData=${encryptedData}`;
    } else if (val == 2) {
      // environment.tradeWasteUrl
      window.location.href  = `${environment.tradeWasteHost}admin/index?csrfToken=${csrfToken}&encryptedData=${encryptedData}`
    } else if (val == 4) {
      // environment.busineslicenseURL
      window.location.href = `${environment.alarmPermitHost}admin/index?csrfToken=${csrfToken}&encryptedData=${encryptedData}`;
    }else if (val == 5) {
      // window.location.href = environment.dogUrl;
      window.open(environment.dogUrl)
    }
  }
  backToLogin(){
    const encryptedData =  sessionStorage.getItem('encryptedData');
    window.location.href = `${environment.alarmPermitHost}?data=${encryptedData}`;
  }
  navigateToIndex(){
      window.location.reload();
  }
  onClickCE(platformKey) {
    debugger;
    console.log("platformKey", platformKey,this.decryptedData.login_by);
    if (!this.decryptedData) {
      let url = environment.alarmPermitHost;
      window.open(`${url}auth/sign-in?id=login`, "_blank");
    }
    if(this.decryptedData.login_by == 1 && platformKey == 2){
      window.open(`${environment.ceUrl}` + `?user=user_login`);
    }
   else if ((this.decryptedData.login_by == 3  || this.decryptedData.login_by == 2) && platformKey == 2) {
      window.open(
      `${environment.ceUrl}auth/verify-ce-login?csrfToken=${this.encryptedData}&platformKey=${platformKey}`
      );
    } else if (
      (this.decryptedData.login_by == 2 || this.decryptedData.login_by == 1 || 
      this.decryptedData.login_by == 3) && platformKey === 1
    ) {
      window.open(
        // environment.ceUrl
       `${environment.ceUrl}auth/verify-ce-login?csrfToken=${this.encryptedData}&platformKey=${platformKey}`
      );  
    }
  }

}
