import { ApplicationService } from 'src/app/core/http/users/application.service';
import { AuthenticationService } from './../../../authentication/authentication.service';
import { NavigationEnd, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { appToaster } from 'src/app/configs';
import { UsersService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit, OnDestroy {
  public currentUser;
  public isApplicationEditable = false;
  public isFormActive = false;
  private subscription: Subscription = new Subscription();
  public activeRoute: any;
  public currentApplication = null;
  public showSaveAndExit = false;
  public paymentAllCompleted = false;
  public event: any;
  public isAdmin = false;
  public showExit:any;
  public newValue:any;
  public saveAndExitHide = false;
  public fee_type:any;
  href:any;
  constructor(
    private router: Router,
    public authenticationService: AuthenticationService,
    private toasterService: ToastrService,
    private userService: UsersService,
    private applicationService: ApplicationService
  ) {

    /** GET CURRENT ROUTE */

    this.subscription.add(this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.event = event;
        this.showExit =  this.userService.getCurrentApplication();
        this.newValue = this.showExit == null?false:this.showExit.response.id;
        // console.log("header component is run ")

        this.subscription.add(
          this.applicationService.obsApplicationData.subscribe((data) => {
            // console.log("header component is run ",data)

            if (data) {
              this.currentApplication = data;
              // console.log("header component is run if data is")

              this.setConditions(this.event);
              this.checkFeeType(this.currentApplication.response)

            } else {
              this.currentApplication = this.userService.getCurrentApplication();
              // this.currentApplication.response  =  this.currentApplication.response.renewal_application || this.currentApplication.response

              // console.log("header component is run data is not",this.currentApplication)
              this.setConditions(this.event);
              if(this.currentApplication){
              this.checkFeeType(this.currentApplication.response)
              }

            }
          }));
      }
    }));
    // this.checkFeeType(this.currentApplication.response)

  }

  ngOnInit() {
// console.log("header component is run ")
    this.subscription.add(this.userService.userDetailsObs.subscribe((data: any) => {
      if (data) {
        this.currentUser = data ? data : null;
      } else {
        if (this)
          this.subscription.add(this.authenticationService.getUserInfo().subscribe(user => {
            this.currentUser = user ? user : null;
          })
          );
      }
    }));
    if (this.event) {
      this.subscription.add(
        this.applicationService.obsApplicationData.subscribe((data) => {
          if (data) {
            this.currentApplication = data;
            // this.currentApplication.response  =  this.currentApplication.response.renewal_application || this.currentApplication.response
            this.setConditions(this.event);
            this.checkFeeType(this.currentApplication.response)

          } else {
            this.currentApplication = this.userService.getCurrentApplication();
            // this.currentApplication.response  =  this.currentApplication.response.renewal_application || this.currentApplication.response

            this.setConditions(this.event);
            if(this.currentApplication){
            this.checkFeeType(this.currentApplication.response)
            }

          }
        }));
        
    }

    this.subscription.add(this.userService.obsHideSaveAndExit.subscribe((data: any) => {
      if (data) {
        this.saveAndExitHide = data;
      }
    }))
    if(this.currentApplication){
      this.checkFeeType(this.currentApplication.response)
    }

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  goToLogin() {
    // this.router.navigateByUrl('auth/sign-in');
    this.href = environment.alarmPermitHost
  }

  onClickProfile() {
    this.router.navigateByUrl('user/update-profile');
  }

  onClickLogout() {
    this.authenticationService.logout();
    this.toasterService.success(appToaster.logoutSuccess);
    this.goToLogin();
  }

  onClickSaveAndExit() {
    debugger
    if (this.activeRoute[this.activeRoute.length - 1] == 'review') {
      this.router.navigate(['user/index']);
    }
    else if (this.activeRoute[this.activeRoute.length - 1].includes('additional-info')) {
      this.applicationService.setSaveAndExit(true);
      this.userService.isSaveAndExit.next({ isSaveAndExit: true, currentForm: 'addedQuestionsForm' });;
    }
    else {
      this.applicationService.setSaveAndExit(true);
      // console.log("route",this.activeRoute[this.activeRoute.length - 1])
      this.userService.isSaveAndExit.next({ isSaveAndExit: true, currentForm: this.activeRoute[this.activeRoute.length - 1] });;
    }
  }

  navigateToIndexPage() {
    this.router.navigate(['user/index']);
  }

  onClickACHAccount() {
    this.router.navigate(['user/ach/index']);
  }

  navigateToPaymentPage() {
    debugger
    this.currentApplication = this.userService.getCurrentApplication();
    // this.currentApplication.response  =  this.currentApplication.response.renewal_application || this.currentApplication.response

    // if (this.currentApplication.response.bl_acknowledge_data && this.currentApplication.response.bl_premises_data.length) {
    //   this.router.navigate(['user/application/payment'], { queryParams: { applicationId: this.currentApplication.response.id, feeType: this.checkFeeType(this.currentApplication.response) } });
    // } else if (this.currentApplication.response.bl_acknowledge_data && this.currentApplication.response.bl_premises_data.length == 0) {
    //   this.toasterService.error('Please add Owners / Officers')
    // }
    // console.log("ffffff=>", this.checkFeeType())
    if(this.currentApplication.response.location_type == 2){
      if(this.currentApplication.response.bl_acknowledge_data && this.currentApplication.response.business_zip_code == 0){
        this.router.navigate(['user/application/payment'], { queryParams: { applicationId: this.currentApplication.response.id, feeType:this.fee_type } });
      }
      else if (this.currentApplication.response.bl_acknowledge_data && this.currentApplication.response.bl_premises_data) {
        this.router.navigate(['user/application/payment'], { queryParams: { applicationId: this.currentApplication.response.id, feeType:this.fee_type } });
      } else if (this.currentApplication.response.bl_acknowledge_data  && this.currentApplication.response.bl_owner.length == 0) {
        this.toasterService.error('Please add Owners / Officers');
      }
    }else if(this.currentApplication.response.location_type == 1){
      if (this.currentApplication.response.bl_acknowledge_data && this.currentApplication.response.bl_owner.length > 0) {
        this.router.navigate(['user/application/payment'], { queryParams: { applicationId: this.currentApplication.response.id, feeType:this.fee_type } });
      } else if (this.currentApplication.response.bl_acknowledge_data  && this.currentApplication.response.bl_owner.length == 0) {
        this.toasterService.error('Please add Owners / Officers');
      }
    }
   

  }

  checkFeeType(application) {
    let paymentData = application ? application.application_payment_data : null
    if (paymentData && paymentData.length > 0) {
      application.application_payment_data.forEach((element) => {
        if (element.fee_type == 0 && element.status == 3) {
          // console.log("enter 1")
          this.fee_type = 1
          return 1;
        } 
        else if (element.fee_type == 0 && (element.status == 0 || element.status == null)) {
          // console.log("enter 0")
          this.fee_type = 0
          return 0;
        }
      });
    } else {
      // console.log("enter outSide")
      this.fee_type = 0
      return 0;
    }
  }

  setConditions(event) {
    if (this.currentApplication && this.currentApplication.response.application_payment_data && this.currentApplication.response.application_payment_data.length > 0) {
      const found = this.currentApplication.response.application_payment_data.filter(el => el['status'] == null || el['status'] == 0 || el['status'] == 2)
      // console.log("value is ===>",found)
      if (found.length == 0) {
        this.paymentAllCompleted = true;
      }
    }
    this.activeRoute = event.url.split('/')
    this.isAdmin = this.activeRoute[this.activeRoute.length - 1].includes('admin=true');
    if (this.activeRoute[3] && this.activeRoute[3].includes('type') && (!this.currentApplication)) {
      this.showSaveAndExit = false;
    } else if ((this.currentApplication && (this.currentApplication.response.id)) && (this.activeRoute[3] && (this.activeRoute[3].includes('type')))) {
      this.showSaveAndExit = true;
    } else if ((this.activeRoute[3] && (!this.activeRoute[3].includes('type')))) {
      this.showSaveAndExit = true;
    } else if ((this.activeRoute[3] && (this.activeRoute[3].includes('type'))) && this.currentApplication && (!this.currentApplication.response.id)) {
      this.showSaveAndExit = false;
    }
    if (this.activeRoute[2] == 'application' && (!this.activeRoute[this.activeRoute.length - 1].includes('payment'))) {
      this.isFormActive = true;
    } else {
      this.isFormActive = false;
    }
    console.log("value is ===>",this.activeRoute[this.activeRoute.length - 1])
  }

  OnClickResubmit() {
    if (this.activeRoute[this.activeRoute.length - 1] == 'review') {
      this.subscription.add(
        this.applicationService.resubmitApplication({ 'application_id': this.currentApplication.response.id }).subscribe((data) => {
          this.router.navigate(['user/index']);
        })
      );
    } else {
      this.userService.isResubmit.next({ isResubmit: true, currentForm: this.activeRoute[this.activeRoute.length - 1] });
    }
  }

  onClickExit() {
    this.router.navigate(['user/index']);
  }

  switchToOtherPortal(val) {
    // this.authenticationService.logout();
    const encryptedData =  sessionStorage.getItem('encryptedData');
    const csrfToken = sessionStorage.getItem('csrfToken');
// console.log("data is ==>",csrfToken)
    // return;
    if (val == 2) {
     window.location.href = `${environment.alarmPermitHost}user/index?csrfToken=${csrfToken}&encryptedData=${encryptedData}`
    } else if (val == 3) {
     window.location.href = `${environment.tradeWasteHost}user/index?csrfToken=${csrfToken}&encryptedData=${encryptedData}`
    }else if (val == 4) {
     window.location.href = `${environment.cannabisHost}user/index?csrfToken=${csrfToken}&encryptedData=${encryptedData}`
    }else if (val == 7) {
      window.location.href = environment.dogUrl;
     }
  }

  navigateToIndex(){
    window.location.reload();
  }
  getCSRFtoken(){
    this.subscription.add(this.authenticationService.getCSRFToken().subscribe((data: any) => {
        sessionStorage.setItem('csrfToken', data);
        console.log("csrf token value is ")
      }));
  }
  backToLogin(){
    const encryptedData =  sessionStorage.getItem('encryptedData');
    window.location.href = `${environment.alarmPermitHost}?data=${encryptedData}`;
  }

}
